<template>
  <div
    id="users"
    class="page-container-table"
  >

    <!--phần header-->
    <!-- <button-all-header
      :contentBtnAdd="'Thêm hồ sơ'"
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideDowload="false"
      :hideExportFile="false"
      :hideImportFile="false"
      @clickDelete="deleteItems"
      @clickDowloadSample="() => {}"
      @clickExportExcel="() => {}"
      @importFile="() => {}"
      @clickAdd="showModalCreate"
      @search="search($event)"
    /> -->

    <div class="d-flex justify-content-between button-header ml-1 mr-1">
      <div
        id="btn-all-header"
        class="d-flex"
      >
        <button-component
          v-b-tooltip.hover.top="'Xóa nhiều'"
          class="button-icon mr-1"
          content-btn=""
          variant="danger"
          icon-btn="Trash2Icon"
          :disabled="!showBtnMultiDelete"
          @click="deleteItems"
        />
      </div>
      <div class="header-action">
        <div class="search-input">
          <b-input-group>
            <b-form-input
              v-model="keySearch"
              type="text"
              class="d-inline-block"
              placeholder="Tìm kiếm"
              @keydown="handleInputChange"
            ></b-form-input>
            <template #append>
              <b-dropdown
                :text="contentDropDown"
                variant="primary"
              >
                <b-dropdown-item @click="handleTypeSearch('content')">
                  Theo nội dung
                </b-dropdown-item>
                <b-dropdown-item @click="handleTypeSearch('key')">
                  Theo tên
                </b-dropdown-item>
              </b-dropdown>

            </template>
          </b-input-group>
        </div>

        <button-component
          class="button-add"
          content-btn="Thêm hồ sơ"
          variant="primary"
          icon-btn="PlusIcon"
          @click="showModalCreate"
        />
      </div>
    </div>

    <vue-good-table
      v-if="!isLoading"
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >

      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field === `${ urlQuery.content && urlQuery.content !== '' ? 'partContent': ''}`">
          NỘI DUNG TÌM KIẾM
        </span>
      </template>
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: số hiệu văn bản-->
        <span v-if="props.column.field === 'Code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>
        <span
          v-else-if="props.column.field === 'partContent'"
          id="column-content-search"
        >
          <span class="content-search">
            {{ props.row.partContent }}
          </span>
        </span>

        <!-- Column: trạng thái-->
        <span v-else-if="props.column.field === 'digitalSignatureStatusString'">
          <b-badge :variant="props.row.digitalSignatureStatus === 'NonDigitalSignature' ? 'light-danger' : 'light-success'">
            {{ props.row.digitalSignatureStatusString }}
          </b-badge>
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <a
            :href="$serverfile + props.row.filePath"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Tải xuống'"
              icon="DownloadIcon"
              size="18"
              class="text-body"
            />
          </a>
          <span
            v-if="props.row.digitalSignatureStatus !== 'DigitalSignature'"
            class="ml-2"
            @click="digitalSigned(props.row)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chữ ký số'"
              icon="Edit2Icon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="showModalEdit(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
    <!-- thêm mới modal  -->
    <modal-create
      :id="modalIdCreate"
      :title="modalType==='add' ?'Thêm hồ sơ' : 'Chỉnh sửa hồ sơ'"
      :dataDetailArchive="dataDetailArchive"
      :type="modalType"
      @accept="handleModal"
    />
    <!-- thêm mới modal  -->
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import vSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreate from './components/ModalCreate.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constant/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    VBTooltip,
    ModalCreate,
    ButtonAllHeader,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'MÃ HỒ SƠ',
          field: 'Code',
          sortable: false,
        },
        {
          label: 'TÊN HỒ SƠ',
          field: 'name',
          sortable: false,
        },
        {
          label: 'DANH MỤC HỒ SƠ',
          field: 'indexFile',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'digitalSignatureStatusString',
          sortable: false,
        },
        {
          label: '',
          field: 'partContent',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '250px',
        },
      ],
      isLoading: false,
      typeSearch: 'key',
      contentDropDown: 'Theo tên',
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal-archive',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      modalType: '',
      totalRecord: 0,
      dataDetailArchive: {},
      archiveId: '',
      modalIdCreate: 'modalIdCreateArchive',
      dataList: [],
      keySearch: '',
      paragraph: null,
    }
  },
  watch: {
    value(n, o) {
      this.keySearch = n
    },
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_ARCHIVE, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm vs xóa
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalIdCreate)
    },
    // click show modal thêm vs xóa
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.archiveId = id
      this.$showAllPageLoading()
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_ARCHIVE}${this.archiveId}`)
      this.dataDetailArchive = data
      this.$hideAllPageLoading()
      this.$bvModal.show(this.modalIdCreate)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // thêm
    async handleModal(param) {
      const formData = new FormData()

      if (this.modalType === 'add') {
        if (param.modelFormData) {
          formData.append('Code', param.code)
          formData.append('Name', param.name)
          formData.append('IndexFile', param.indexFile)
          formData.append('IsSecure', param.modelFormData.isSecure)
          formData.append('formFile', param.modelFormData.files)
          formData.append('FileName', param.fileName)
        } else {
          formData.append('Code', param.Code)
          formData.append('Name', param.name)
          formData.append('IndexFile', param.indexFile)
        }
        this.$showAllPageLoading()
        const res = await axiosApiInstance.post(ConstantsApi.CREATE_ARCHIVE, formData)
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalIdCreate)
          this.fetchData(this.urlQuery)
        } else {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Thêm mới không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } else if (this.modalType === 'edit') {
        if (param.modelFormData) {
          formData.append('Id', this.archiveId)
          formData.append('Code', param.code)
          formData.append('Name', param.name)
          formData.append('IndexFile', param.indexFile)
          formData.append('IsSecure', param.modelFormData.isSecure)
          formData.append('formFile', param.modelFormData.files)
          formData.append('FileName', param.fileName)
        } else {
          formData.append('Code', param.Code)
          formData.append('Name', param.name)
          formData.append('IndexFile', param.indexFile)
        }
        this.$showAllPageLoading()
        const res = await axiosApiInstance.put(ConstantsApi.EDIT_ARCHIVE, formData)
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Chỉnh sửa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalIdCreate)
          this.fetchData(this.urlQuery)
        } else {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Chỉnh sửa không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      }
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_ARCHIVE, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    handleInputChange(event) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.search(event.target.value)
      }, 500)
    },

    // Lấy danh sách khi tìm kiếm
    async search(val) {
      if (val != null && val !== '') {
        this.urlQuery.pageNumber = 1
        this.urlQuery[`${this.typeSearch}`] = val
        await this.fetchData(this.urlQuery)
        this.paragraph = document.getElementsByClassName('content-search')
        if (this.typeSearch === 'content') {
          this.paragraph.forEach(item => {
            item.className = 'content-search d-block'
            let textToSearch = this.keySearch
            textToSearch = textToSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
            const pattern = new RegExp(`${textToSearch}`, 'gi')
            // eslint-disable-next-line no-undef
            item.innerHTML = item.textContent.replace(pattern, match => `<mark>${match}</mark>`)
          })
        }
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery[`${this.typeSearch}`] = ''
        this.paragraph.forEach(item => {
          item.className = 'content-search d-none'
        })
        await this.fetchData(this.urlQuery)
      }
    },
    digitalSigned(val) {
      val.fileUrl = this.$serverfile + val.filePath
      val.fileUploadHandler = `${this.$serverfile}home/sign-file-storage/${val.id}`
      const prms = {}
      prms.FileUploadHandler = val.fileUploadHandler
      prms.SessionId = ''
      prms.FileName = val.fileUrl
      prms.MetaData = [val]
      // eslint-disable-next-line no-undef
      vgca_sign_file(JSON.stringify(prms), this.signCallback)
    },
    signCallback(val) {
      val = JSON.parse(val)
      if (val.Status > 0) {
        this.$bvToast.toast(val.Message, {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        this.$bvToast.toast('Ký số thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }
    },

    handleTypeSearch(val) {
      if (val === 'content') {
        this.contentDropDown = 'Theo nội dung'
        this.urlQuery.key = ''
        this.typeSearch = val
        this.search(this.keySearch)
      } else {
        this.contentDropDown = 'Theo tên'
        this.urlQuery.content = ''
        this.typeSearch = val
        this.search(this.keySearch)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
  .header-action {
    width: 100%;
    flex-wrap: wrap;
  }
  .search-input {
    width: 400px;
  }
  .vselect-append {
     .vs__dropdown-toggle {
      border: 1px solid #A6A8B0;
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
      background-color: #EFEFEF;
      border-radius: unset;
    }
  }
  mark {
    background-color: #FFFF00;
  }
}
</style>
